<template>
  <v-card class="mx-auto text-center" v-if="config">
    <v-card-title>
      {{ config.title }}
    </v-card-title>
    <v-card-text class="pb-0">
      <v-sparkline
        :value="config.values"
        :labels="config.labels"
        height="75"
        padding="12"
        stroke-linecap="round"
        border="1"
         style="border: 1px solid silver"
      ></v-sparkline>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-center">
      <v-btn color="primary">Go to Report</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">

export default {
  props: {
    config: Object
  },
  data () {
    return {
    }
  },
  computed: {
  },
  created: async function () {
  },
  mounted: function () {
  },
  methods: {
  }
}

</script>
