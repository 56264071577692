<template>
  <v-card class="mb-2">
    <v-card-title>Most Popular Artworks (all products)</v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-for="(artwork, index) in artworks" xl="4" lg="4" sm="4" cols="12" :key="index">
          <v-img :src="imageUrl + artwork.artworkImage"></v-img>
          <!-- <h5 class="mt-2 text-center">{{artwork.title}}</h5> -->
          <h5 class="mt-2 text-center">{{(artwork.numItems/totalProducts*100).toFixed(2)}}%</h5>
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>
</template>

<script>
import ReportApi from '@/api/publishedArtist/report'

export default {
  props: {
    dateRange: Array,
  },
  data () {
    return {
      imageUrl: `${process.env.VUE_APP_IMG_URL}/300px/`,
      artworks: [],
      totalProducts: 0,
    }
  },
  computed: {
  },
  created: async function () {
    const sales = await ReportApi.loadArtistSalesByArtworkAndProduct({dateRange: this.dateRange})
    console.log(sales)
    this.artworks = sales.data.slice(0,6)
    this.totalProducts = this.artworks.reduce( function(total, currentValue) { return total += currentValue.numItems }, 0 )
  },
  mounted: function () {
  },
  methods: {
    getImage (image) {
      return require('@/assets/' + image)
    },
  }
}

</script>
<style lang="scss" scoped>

</style>
