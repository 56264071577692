<template>
  <v-card class="mb-2">
    <v-toolbar dense color="primary" dark>
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <LineChart v-if="chartData.data"
      :chart-options="chartData.options"
      :chart-data="chartData.data"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      responsive="true"
    />
  </v-card>
</template>

<script>
import ReportApi from '@/api/publishedArtist/report'
import { Line as LineChart } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale)

export default {
  name: 'ChartWidget',
  props: {
    apiCall: Function,
    chartId: null,
    title: null,
    dateRange: Array,
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  components: {
    LineChart,
  },
  data () {
    return {
      chartData: {}
    }
  },
  async mounted () {
  },
  watch: {
    async dateRange () {
      this.chartData = await this.apiCall(this.dateRange)
    }
  },
  methods: {
  }
}
</script>
